.App {
  background-color: #39393f;
  background: linear-gradient(180deg, rgba(32,34,37,1) 0%, rgba(57,57,63,1) 32%, rgba(57,57,63,1) 100%);
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  color: ghostwhite;
  transition:ease-in-out all 0.3s;
  overflow: hidden;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  justify-content: flex-start;
  flex: 1 1 auto;
  overflow: auto;
  overflow-x: hidden;
}

.home iframe {
  flex: 0 0 560px;
  width: 100%;
  border: 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.home img {
  max-width: 15%;
  max-height: 10%;
  flex: 1 1 auto;
}

.main-content {
  flex: 1 1 auto;
  overflow: auto;
}

.main-content iframe {
  flex: 0 0 560px;
  width: 100%;
  height: 560px;
  border: 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.markdown-holder {
  display: flex;
  color: ghostwhite;
  text-align: left;     
  flex-direction: column;
  align-items: center;
  transition:ease-in-out all 0.3s;
  flex: 1 1 400px;
  overflow-y: visible;
  overflow-x: hidden;
  white-space: normal;
  padding: 20px;
}

.markdown-content {
  display: flex;
  flex-direction: column;
  flex: 0 1 400px;
  max-width: 800px;
}

.markdown-content img {
  flex: 1 1 auto;
  max-width: 500px;
  height: auto;
  width: 80vw;
  overflow-x: scroll;
}

.markdown-content p {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0;
}

.markdown-content h5 {
  margin: 0.8rem;
}

.App-logo {
  cursor: pointer;
}

.App-header {
  background-color: #202225;
  height: 60px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  color: ghostwhite;
  padding: 5px;
  vertical-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

.App-header-bots {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 1.0rem;
  overflow-x: auto;
}

.App-header-bot-item {
  margin: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ghostwhite;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
  border-radius: 4px;
}

.App-header-bot-item-selected {
  margin: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ghostwhite;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 2px dodgerblue solid;
}

.App-header-bot-item:hover {
  margin: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ghostwhite;
  background-color: #3b5b861a;
  border-color: transparent;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
  border-radius: 4px;
}

.App-header img {
  max-width: 100%;
  max-height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
.App-header p {
  max-width: 100%;
  max-height: 100%;
  margin-left: 5px;
  margin-right: 10px;
}
.App-header-login {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-self: center;
  justify-content: flex-end;
  align-items: center;
}

.img-profile-icon {
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  width: 50px;
  height: 50px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
}

.title2 {
  font-weight: 400;
  font-size: 1.0rem;
}

.App-link {
  color: #61dafb;
}

.App-header-separator {
  width: 1px;
  height: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;
  background-color: ghostwhite;
}

.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.container {
  position: relative;
  display: inline-block;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.14);
  background-color: #111217;
  border-top-left-radius: 10px 10px;
  border-top-right-radius: 10px 10px;
  border-bottom-right-radius: 10px 10px;
  border-bottom-left-radius: 10px 10px;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px 12px;
  font-size: 14px;
  text-align: right;
}

.dropdown li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}